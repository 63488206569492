.App {
  text-align: center;
  min-height: 88vh;
  margin-top: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.right {
  margin-right: 0;
}

.padding-top{
  padding-top: 20px;
}
table {
  padding: 10px;
  border: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
  /* margin-left: auto;
  margin-right: auto; */
}

/* Container */

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 992px){
  .container, .container-lg, .container-md, .container-sm {
      max-width: 960px;
  }
}
@media (max-width: 768px){
  .container, .container-md, .container-sm {
      max-width: 720px;
  }
}
@media (max-width: 576px){
  .container, .container-sm {
      max-width: 540px;
  }
}


/* Inputs */
/* input[type=text], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

input[type=date] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

input[type=textarea] {
  width: 100%;
  height: 100px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

input[type=password], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

label {
  padding: 12px 12px 0px 0;
  display: inline-block;
}

input[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

input[type=submit]:hover {
  background-color: #45a049;
} */

.form-container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.flex-div{
  padding-top:15px;
  display: flex;
  background-color: #f2f2f2;
}
.flex-div div {
  -ms-flex: 1;  /* IE 10 */  
  flex: 1;
}
.error{
  color: red;
  font-size: small;
  margin: 0;
}
.success{
  color: green;
  font-size: small;
  margin: 0;
}
.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


.btn-group-sm>.btn {
  font-size: 1rem !important;
}

table thead {
  width: 100%;
}
/* table tbody {
  height: 200px;
  overflow-y: scroll;
  width: 100%;
  position: relative
} */

hr {
  border-top: 0
}

.custom-switch {
  padding-left: 0 !important;
}

.custom-control {
  padding-left: 0 !important;
}

.react-bs-table-pagination {
  text-align: left;
}

.react-bs-table-tool-bar {
  text-align: left;
}


/* Scroll to top */


.scroll-to-top {
  position: fixed;
  bottom: 2rem;
  right: 0.5rem; 
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
  z-index: 100;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.top-link {
  transition: all .25s ease-in-out;
  position: fixed;
  bottom: 1.4rem;
  right: 0.5rem; 
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  /* margin: 0 3em 3em 0; */
  border-radius: 50%;
  padding: .25em;
  width: 50px;
  height: 50px;
  background-color: #F8F8F8;
}

.top-link.show {
  visibility: visible;
  opacity: 1;
}
.top-link.hide {
  visibility: hidden;
  opacity: 0;
}
.top-link svg {
  fill: #999;
  width: 24px;
  height: 12px;
}
.top-link:hover {
  background-color: #E8E8E8;
}
.top-link:hover svg {
  fill: #777;
}

.screen-reader-text {
  position: absolute;
  clip-path: inset(50%);
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  word-wrap: normal !important;
  clip: rect(1px, 1px, 1px, 1px);
}
.screen-reader-text:focus {
  display: block;
  top: 5px;
  left: 5px;
  z-index: 100000;
  clip-path: none;
  background-color: #eee;
  padding: 15px 23px 14px;
  width: auto;
  height: auto;
  text-decoration: none;
  line-height: normal;
  color: #444;
  font-size: 1em;
  clip: auto !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #999; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777; 
}

.noMargin {
  margin-left: unset !important;
  margin-right: unset !important;
}

.centerText {
  text-align: center;
}

.modal-90w{
  margin-left: auto;
  margin-right: auto;
  width: 350px;
}

.fullWidth {
  width: 100%;
}

.navBarLink {
  margin: 5px;
}

.modal {
  top: 50px !important;
}

.buttonSpacing-m-l {
  margin-left: 352px;
}

.btn-group>.btn-group>.btn, .btn-group>.btn:not(.dropdown-toggle) {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  margin-right: 5px;
}

.refreshErr {
  top: 45px;
}

.float-left{
  float: left;
}

.popover {
  z-index: 1040;
}

.progress-bar {
  margin-left: unset !important;
  margin-right: unset !important;
}

.page-item.active .page-link {
   z-index: 0 !important;
}

.medium-text {
  font-size: small;
}

.form-control:focus
{
    -webkit-appearance:none;
    box-shadow: none !important;
}

.react-confirm-alert-overlay {
  z-index: 1050 !important;
  background: rgba(22, 22, 22, 0.7) !important;
}

.react-confirm-alert-body {
  font-family: Ubuntu Light !important;
  padding: 0px !important;
  color: #F4F4F4 !important;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212 !important;
  border-radius: 0px !important;
}

.confirm {
  width: 500px !important;
  height: 180px !important;
}

.react-confirm-alert-button-group {
  position: relative;
  bottom: -35px;
}

.react-confirm-alert-button-group > button {
  width: 100% !important;
  height: 64px;
  padding: 6px 18px;
  padding-bottom: 25px !important;
  text-align: left;
  margin-right: 0px !important;
  border-radius: 0px !important;
  font-size: 14px !important;
}

.react-confirm-alert-button-group > .deleteBtn {
  margin-right: 2px;
  background: rgb(211, 45, 75);
  color: rgba(255, 255, 255, 0.87);
}

.react-confirm-alert-button-group > .deleteBtn:hover {
  background: #EB4F6B;
}

.react-confirm-alert-button-group > .cancelBtn {
  margin-left: 2px;
  background: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.87);
}

.react-confirm-alert-button-group > .cancelBtn:hover {
  background: rgba(255, 255, 255, 0.38);
}

.shareUser {
  width: 800px !important;
  height: "auto" !important;
}

table#projectUserTable th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #525252;
  border: 0;
}

table#projectUserTable td {
  font-size: 14px;
  border: 0;
}

table#projectUserTable tr:nth-child(odd) {
  background-color: #52525257;
}

table#projectUserTable tr:nth-child(even) {
  background-color: #5252521c;
}


@media screen and (max-width: 1199px) {
  .buttonSpacing-m-l {
    margin-left: 270px;
  }
}
@media screen and (max-width: 1090px) {
  #loginDropdown>.btn {
    padding: 0;
  }

  #loginDropdown>.dropdown-toggle {
    padding: 0;
  }
  #loginDropdown>.btn.focus{
    padding: 0;
  }

  #loginDropdown>.btn:focus {
    padding: 0;
  }
}
@media screen and (max-width: 991px) {
      
  .buttonSpacing {
    margin: 15px;
  }

  .buttonSpacing-m-l {
    margin-left: 0px;
  }
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25, .col-75, input[type=submit] {
    width: 100%;
    margin-top: 0;
  }
  .scroll-to-top {
    display: none;
  }
  #loginDropdown>.btn {
    padding: 0;
  }
  
  #loginDropdown>.dropdown-toggle {
    padding: 0;
  }
  #loginDropdown>.btn.focus{
    padding: 0;
  }
  #loginDropdown>.btn:focus {
    padding: 0;
  }
}



