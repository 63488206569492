/* input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.percent-input {
  position: relative;
  display: inline-block;
}

/* .percent-input::after {
  color: black;
  content: '%';
  font-family: "Ubuntu Mono";
  width: 1em;
  height: 1em;
  position: absolute;
  top: 46%;
  right: 5px;
  display: block;
  font-size: 16px;
  transform: translateY(-0%);
} */

.customInputTooltip {
	color: rgba(255, 255, 255, 0.87) !important;
	background-color: #000000 !important;
  padding: 6px !important;
  text-align: center !important;
  max-width: 300px;
	&.place-top {
    &:after {
    border-top-color: #000000 !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
    }
	}
}

.__react_component_tooltip .multi-line {
  text-align: center !important;
}