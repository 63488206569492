.electricalItemsTabs .nav-tabs {
    border-bottom: 0px;
}

.electricalItemsTabs .nav-item.show .nav-link,
.electricalItemsTabs .nav-tabs .nav-link.active {
    background-color: #262626;
    color: white;
    border-color: transparent;
}

.electricalItemsTabs .nav-item.show .nav-link,
.electricalItemsTabs .nav-tabs .nav-link:focus {
    outline: none;
}

.electricalItemsTabs .nav-item.show .nav-link,
.electricalItemsTabs .nav-tabs .nav-link {
    flex-grow: 1;
    text-align: left;
    margin-left: 3px;
    background-color: #444141;
    color: white;
    border: 0px;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
}

.electricalItemsTabs .nav-item.show .nav-link,
.electricalItemsTabs .nav-tabs .nav-link:first-child {
    margin-left: 0px;
}

.electricalItemsTabs .nav-item {
    margin-bottom: 0px;
    font-size: 13px;
}

.electricalItemsTabs .nav-item.show .nav-link,
.electricalItemsTabs .nav-tabs .nav-link.disabled {
    background-color: #444141;
    display: none;
}

.tab-content {
    background-color: #262626;
    color: white;
}

.tab-content .tab-content {
    height: 0vh;
}

#electricalItemsTabs .nav-link {
    padding-left: 10px;
}

.electricalItemsPills .nav-link.active,
.nav-pills .show .nav-link {
    background-color: #228C22 !important;
    color: black !important;
    margin: 0;
    padding-bottom: 1px;
}

.electricalItemsPills .nav-link,
.nav-pills .show .nav-link {
    margin: 0;
    font-size: 12px;
}

.electricalItemsPills .nav-link {
    padding-left: 0.5rem;
    border-radius: 0.25rem;
    padding-top: 0rem;
    padding-right: 0.5rem;
    padding-bottom: 1px;
    background-color: black;
    color: white;
    border-width: thin;
    border-style: solid;
    border-color: white;
}

.electricalItemsPills .nav-item {
    margin: 0;
    margin-left: 10px;
    margin-top: 13px;
    padding-left: 10px;
}

.collapseButtons {
    margin: "10px";
    margin-left: "20px";
    text-align: "left";
    height: "100%";
    display: "flex";
    width: "30%";
}

.electricalItemsPills {
    margin-bottom: 10px;
}

.electricalItemsPills .nav-link.disabled {
    color: #6c757d;
    background-color: #444141;
}

.electricalItemsPills .form-control {
    height: calc(1.5em + .75rem - 10px);
}

.electricalItemsPillsCollapse [class$="control"] {
    border-width: 0px;
    border-bottom-width: 3px !important;
    border-color: #228C22 !important;
    box-shadow: 0 0 0 0px #6c757d !important;
    background: white !important;
    height: 40px;
}

.electricalItemsPillsCollapse [class$="control"]:hover {
    border-color: #228C22 !important;
}

.electricalItemsPillsCollapse [class$="single-value"]:active {
    background-color: #6c757d !important;
}

.electricalItemsPillsCollapse [class$="-electricalItemsConsumptionCollapse"] {
    display: flex;
    margin: 0px;
    margin-top: 10px;
    width: 96.5%;
}

.electricalItemsPillsCollapse .inactivebtn {
    font-size: inherit;
    padding-left: 0.5rem;
    border-radius: 0.25rem;
    padding-top: 1px;
    padding-right: 0.5rem;
    padding-bottom: 1px;
    background-color: black;
    color: white;
    border-width: thin;
    border-style: solid;
    border-color: white;
    margin: 0;
    margin-right: 5px;
    margin-bottom: 4px;
}

.electricalItemsPillsCollapse .inactivebtn:hover {
    color: white !important;
}

.electricalItemsPillsCollapse .activebtn {
    font-size: inherit;
    padding-left: 0.5rem;
    border-radius: 0.25rem;
    padding-top: 1px;
    padding-right: 0.5rem;
    padding-bottom: 1px;
    border-width: thin;
    border-style: solid;
    border-color: white;
    background-color: #148AFF !important;
    color: black;
    margin: 0;
    margin-right: 5px;
    margin-bottom: 4px;
}

.electricalItemsPillsCollapse .activebtn:hover {
    color: black !important;
}

.electricalItemsPillsCollapse .carbonFootprint {
    text-align: right;
    margin-right: 10px;
}

div.electricalItemsBottom {
    width: 100%;
    text-align: right;
    margin-top: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
    background-color: #161616;
}

div.electricalItemsBottom .content {
    width: 100%;
    padding-right: 20px;
}

@media screen and (max-width: 1199px) {
    .electricalItemsPillsCollapse [class$="-electricalItemsConsumptionCollapse"] {
        display: flex;
        width: 96.5%;
    }

    div.electricalItemsBottom .content {
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .electricalItemsPillsCollapse [class$="-electricalItemsConsumptionCollapse"] {
        display: flex;
        width: 96.5%;
    }

    div.electricalItemsBottom .content {
        width: 100%;
    }
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .electricalItemsPillsCollapse [class$="-electricalItemsConsumptionCollapse"] {
        display: flex;
        width: 96.5%;
    }

    div.electricalItemsBottom .content {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    div.electricalItemsBottom .content {
        width: 100%;
    }
}