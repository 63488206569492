
.multicolor-bar {
	margin: 0px;
}

.multicolor-bar .values .value {
	float: left;
	text-align: center;
}

.multicolor-bar .scale .graduation {
	float: left;
	text-align: center;
}

.multicolor-bar .bars .bar {
	float: left;
	height: 15px;
}

/* .multicolor-bar .bars div.bar:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.multicolor-bar .bars div.bar:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
} */

.multicolor-bar .legends {
	text-align: center;
}


.multicolor-bar .legends .legend {
    display: inline-block;
    margin: 0 5px;
    text-align: center;
}

.multicolor-bar .legends .legend .dot {
	font-size: 25px;
	vertical-align: middle;
}

.multicolor-bar .legends .legend .label {
	margin-left: 2px;
	vertical-align: middle;
}

.customTooltip {
	color: rgba(255, 255, 255, 0.87) !important;
	background-color: rgb(38, 38, 38) !important;
	padding: 3px !important;
	&.place-top {
	&:after {
	border-top-color: rgb(38, 38, 38) !important;
	border-top-style: solid !important;
	border-top-width: 6px !important;
	}
	}
   }