.custom-toast {
  text-align: center;
  float: right;
  top: 66px;
  position: fixed;
  right: 8px;
  z-index: 1045;
  background-color: rgba(255, 255, 255, 1);
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.custom-toast2 {
  margin: 0;
  position: fixed;
  top: 40%;
  min-width: 420px;
  left: 50%;
  z-index: 1045;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 1);
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.custom-toast-pdf {
  position: fixed;
  top: 40%;
  min-width: 420px;
  left: 50%;
  z-index: 1045;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 1);
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

/* .custom-toast-pdf {
  position: fixed;
  z-index: 1045;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Add a box shadow to make the toast pop out */
/* } */

.toast-header {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  opacity: 1;
  display: flex;
  justify-content: space-between;
}

.toast-body {
  text-align: left;
}