
.inactivebtn {
    font-size: 13px;
    border-radius: 8px;
    padding: 5px 10px 5px 11px;
    color: rgba(255, 255, 255, 0.38);
    border-width: 0px;
    margin: 0;
    margin-right: 8px;
    margin-bottom: 8px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18);
    outline: none !important;
  }
  
.inactivebtn:hover {
    /* background-color: #525252; */
    color: #f0f0f0 !important;
    outline: none !important;
  }
  
.inactivebtn:focus {
    box-shadow: none;
}
.activebtn {
    font-size: 13px;
    border-radius: 8px;
    padding: 5px 10px 5px 11px;
    border-width: 0px;
    background-color: #525252 !important;
    color: #f0f0f0;
    margin: 0;
    margin-right: 8px;
    margin-bottom: 8px;
    outline: none !important;
}

.activebtn:hover {
    background-color: #525252;
    color: #f0f0f0 !important;
    outline: none !important;
}
  
.activebtn:focus {
    box-shadow: none;
}


.errorbtn {
    font-size: 13px;
    border-radius: 8px;
    padding: 5px 10px 5px 11px;
    color: rgba(255, 255, 255, 0.38);
    border-color: rgb(235, 79, 107);
    border-width: 1px;
    margin: 0;
    margin-right: 8px;
    margin-bottom: 8px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.086), rgba(255, 255, 255, 0.086)), rgb(18, 18, 18);
    outline: none !important;
  }
  
.errorbtn:hover {
    /* background-color: #525252; */
    color: #f0f0f0 !important;
    outline: none !important;
  }
  
.errorbtn:focus {
    box-shadow: none;
}
