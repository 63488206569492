$fuelColor: #F99500;
$electricityColor: #FFFF00;
$deliveryColor: #7444DA;
$paperColor: #FFFFF0;
$plasticColor: #40A0B5;
$metalColor: #d7dbdc;
$transportColor: #f37d7d;
$electricalItemsColor: #228C22;
$aviationColor: #6f91ad;
$seaFreightColor: #009dc4;
$wasteColor: #40A0B5;
$dataCentreColor: #66FFCC;
$waterColor: #148AFF;

.filterDiv {
    cursor: pointer;
    text-align: center;
    /* background-color: #262626; */
    width: 100%;
    height: 38px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.filterDiv:hover {
    background-color: #262626;
}

.filterDiv:active {
    background-color: #262626a1;
}

/* CHECKBOXES */

#loader {
	color: #008cff;
	height: 40px;
	left: 45%;
	position: absolute;
	top: 45%;
	width: 30%;
}

.e-label {
    color: rgba(255, 255, 255, 0.87) !important;
    font-family: Ubuntu !important;
}

.e-checkbox-wrapper {
  margin-top: 18px;
}

.e-checkbox-wrapper.e-primary:hover .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: #e03872;
}

.e-checkbox-wrapper.e-fuel .e-frame,
.e-checkbox-wrapper.e-fuel .e-checkbox + .e-frame { /* csslint allow: adjoining-classes */
  background-color: $fuelColor;
  border-color: $fuelColor;
}

.e-checkbox-wrapper.e-fuel .e-frame.e-check,
.e-checkbox-wrapper.e-fuel .e-checkbox:focus + .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $fuelColor;
}

.e-checkbox-wrapper.e-fuel:hover .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $fuelColor;
}

.e-checkbox-wrapper.e-electricity .e-frame,
.e-checkbox-wrapper.e-electricity .e-checkbox + .e-frame { /* csslint allow: adjoining-classes */
  background-color: $electricityColor;
  border-color: $electricityColor;
}

.e-checkbox-wrapper.e-electricity .e-frame.e-check,
.e-checkbox-wrapper.e-electricity .e-checkbox:focus + .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $electricityColor;
}

.e-checkbox-wrapper.e-electricity:hover .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $electricityColor;
}

.e-checkbox-wrapper.e-delivery .e-frame,
.e-checkbox-wrapper.e-delivery .e-checkbox + .e-frame { /* csslint allow: adjoining-classes */
  background-color: $deliveryColor;
  border-color: $deliveryColor;
}

.e-checkbox-wrapper.e-delivery .e-frame.e-check,
.e-checkbox-wrapper.e-delivery .e-checkbox:focus + .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $deliveryColor;
}

.e-checkbox-wrapper.e-delivery:hover .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $deliveryColor;
}

.e-checkbox-wrapper.e-dataCentre .e-frame,
.e-checkbox-wrapper.e-dataCentre .e-checkbox + .e-frame { /* csslint allow: adjoining-classes */
  background-color: $dataCentreColor;
  border-color: $dataCentreColor;
}

.e-checkbox-wrapper.e-dataCentre .e-frame.e-check,
.e-checkbox-wrapper.e-dataCentre .e-checkbox:focus + .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $dataCentreColor;
}

.e-checkbox-wrapper.e-dataCentre:hover .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $dataCentreColor;
}

.e-checkbox-wrapper.e-water .e-frame,
.e-checkbox-wrapper.e-water .e-checkbox + .e-frame { /* csslint allow: adjoining-classes */
  background-color: $waterColor;
  border-color: $waterColor;
}

.e-checkbox-wrapper.e-water .e-frame.e-check,
.e-checkbox-wrapper.e-water .e-checkbox:focus + .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $waterColor;
}

.e-checkbox-wrapper.e-water:hover .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $waterColor;
}

.e-checkbox-wrapper.e-paper .e-frame,
.e-checkbox-wrapper.e-paper .e-checkbox + .e-frame { /* csslint allow: adjoining-classes */
  background-color: $paperColor;
  border-color: $paperColor;
}

.e-checkbox-wrapper.e-paper .e-frame.e-check,
.e-checkbox-wrapper.e-paper .e-checkbox:focus + .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $paperColor;
}

.e-checkbox-wrapper.e-paper:hover .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $paperColor;
}


.e-checkbox-wrapper.e-plastic .e-frame,
.e-checkbox-wrapper.e-plastic .e-checkbox + .e-frame { /* csslint allow: adjoining-classes */
  background-color: $plasticColor;
  border-color: $plasticColor;
}

.e-checkbox-wrapper.e-plastic .e-frame.e-check,
.e-checkbox-wrapper.e-plastic .e-checkbox:focus + .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $plasticColor;
}

.e-checkbox-wrapper.e-plastic:hover .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $plasticColor;
}

.e-checkbox-wrapper.e-metal .e-frame,
.e-checkbox-wrapper.e-metal .e-checkbox + .e-frame { /* csslint allow: adjoining-classes */
  background-color: $metalColor;
  border-color: $metalColor;
}

.e-checkbox-wrapper.e-metal .e-frame.e-check,
.e-checkbox-wrapper.e-metal .e-checkbox:focus + .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $metalColor;
}

.e-checkbox-wrapper.e-metal:hover .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $metalColor;
}

.e-checkbox-wrapper.e-transport .e-frame,
.e-checkbox-wrapper.e-transport .e-checkbox + .e-frame { /* csslint allow: adjoining-classes */
  background-color: $transportColor;
  border-color: $transportColor;
}

.e-checkbox-wrapper.e-transport .e-frame.e-check,
.e-checkbox-wrapper.e-transport .e-checkbox:focus + .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $transportColor;
}

.e-checkbox-wrapper.e-transport:hover .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $transportColor;
}

.e-checkbox-wrapper.e-electricalItems .e-frame,
.e-checkbox-wrapper.e-electricalItems .e-checkbox + .e-frame { /* csslint allow: adjoining-classes */
  background-color: $electricalItemsColor;
  border-color: $electricalItemsColor;
}

.e-checkbox-wrapper.e-electricalItems .e-frame.e-check,
.e-checkbox-wrapper.e-electricalItems .e-checkbox:focus + .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $electricalItemsColor;
}

.e-checkbox-wrapper.e-electricalItems:hover .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $electricalItemsColor;
}

.e-checkbox-wrapper.e-aviation .e-frame,
.e-checkbox-wrapper.e-aviation .e-checkbox + .e-frame { /* csslint allow: adjoining-classes */
  background-color: $aviationColor;
  border-color: $aviationColor;
}

.e-checkbox-wrapper.e-aviation .e-frame.e-check,
.e-checkbox-wrapper.e-aviation .e-checkbox:focus + .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $aviationColor;
}

.e-checkbox-wrapper.e-aviation:hover .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $aviationColor;
}

.e-checkbox-wrapper.e-seaFreight .e-frame,
.e-checkbox-wrapper.e-seaFreight .e-checkbox + .e-frame { /* csslint allow: adjoining-classes */
  background-color: $seaFreightColor;
  border-color: $seaFreightColor;
}

.e-checkbox-wrapper.e-seaFreight .e-frame.e-check,
.e-checkbox-wrapper.e-seaFreight .e-checkbox:focus + .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $seaFreightColor;
}

.e-checkbox-wrapper.e-seaFreight:hover .e-frame.e-check { /* csslint allow: adjoining-classes */
  background-color: $seaFreightColor;
}



.e-checkbox-wrapper .e-check::before, .e-css.e-checkbox-wrapper .e-check::before {
  // content: '' !important; 
  color: #161616 !important;
}