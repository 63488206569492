.aviationTabs .nav-tabs {
    border-bottom: 0px;
}

.aviationTabs .nav-item.show .nav-link, .aviationTabs .nav-tabs .nav-link.active{
    background-color: #262626;
    color: white;
    border-color: transparent;
}

.aviationTabs .nav-item.show .nav-link, .aviationTabs .nav-tabs .nav-link:focus{
    outline: none;
}

.aviationTabs .nav-item.show .nav-link, .aviationTabs .nav-tabs .nav-link{
    flex-grow: 1;
    text-align: left;
    margin-left: 3px;
    background-color: #444141;
    color: white;
    border: 0px;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    width: 0px;
}

.aviationTabs .nav-item.show .nav-link, .aviationTabs .nav-tabs .nav-link:first-child{
    margin-left: 0px;
}

.aviationTabs .nav-item{
    margin-bottom: 0px;
    font-size: 13px;
}

.aviationTabs .nav-item.show .nav-link, .aviationTabs .nav-tabs .nav-link.disabled {
    background-color: #444141;
    display: none;
}

.tab-content {
    background-color: #262626;
    color: white;
    /* height: 25vh; */
}

.tab-content .tab-content {
     height: 0vh; 
}

#aviationTabs .nav-link {
    padding-left: 10px;
}

.aviationPills .nav-link.active, .nav-pills .show>.nav-link {
    /* background-color: rgb(170 189 209) !important; */
    background-color: #6f91ad !important;
    color: black !important;
    margin: 0;
    padding-bottom: 1px;
}

.aviationPills .nav-link, .nav-pills .show>.nav-link {
    margin: 0;
    font-size: 12px;
}

.aviationPills .nav-link {
    padding-left: 0.5rem;
    border-radius: 0.25rem;
    padding-top: 1px;
    padding-right: 0.5rem;
    padding-bottom: 1px;
    background-color: black;
    color: white;
    border-width: thin;
    border-style: solid;
    border-color: white;
}

.aviationPills .nav-item {
    margin: 0;
    margin-left: 10px;
    margin-top: 13px;
    padding-left: 10px;
}

.collapseButtons {
    margin: "10px";
    margin-left: "20px";
    text-align: "left";
    height: "100%";
    display: "flex";
    width: "30%";
}

.aviationPills{
    margin-bottom: 10px;
}

.aviationPills .nav-item1 {
    display: none;
}

.aviationPills .nav-link.disabled {
    color: #6c757d;
    background-color: #444141;
}

.aviationPills .form-control {
    height: calc(1.5em + .75rem - 10px);
}

.aviationPillsCollapse [class$="control"] {
    border-width: 0px;
    border-bottom-width: 3px !important;
    border-color: #6f91ad;
    box-shadow: 0 0 0 0px #6c757d !important;
    background: white !important;
    height: 40px;
}


.aviationPillsCollapse [class$="control"]:hover {
    border-color: #6f91ad;
}

.aviationPillsCollapse [class$="single-value"]:active  {
    background-color: #6c757d;
}



.aviationPillsCollapse [class$="-freightCollapse"] {
    display: flex;
    margin: 0px;
    margin-top: 10px;
    width: 96.5%;
}

.aviationPillsCollapse [class$="-passengerCollapse"] {
    display: flex;
    margin: 0px;
    margin-top: 10px;
    width: 96.5%;
}

.aviationPillsCollapse .inactivebtn {
    font-size: 12px;
    padding-left: 0.5rem;
    border-radius: 0.25rem;
    padding-top: 1px;
    padding-right: 0.5rem;
    padding-bottom: 1px;
    background-color: black;
    color: white;
    border-width: thin;
    border-style: solid;
    border-color: white;
    margin: 0;
    margin-right: 5px;
    margin-bottom: 4px;
  }
  
  .aviationPillsCollapse .inactivebtn:hover {
    color: white !important;
  }
  
  .aviationPillsCollapse .activebtn {
    font-size: 12px;
    padding-left: 0.5rem;
    border-radius: 0.25rem;
    padding-top: 1px;
    padding-right: 0.5rem;
    padding-bottom: 1px;
    border-width: thin;
    border-style: solid;
    border-color: white;
    background-color: #6f91ad !important;
    color: black;
    margin: 0;
    margin-right: 5px;
    margin-bottom: 4px;
  }
  
.aviationPillsCollapse .activebtn:hover {
    color: black !important;
}

.aviationPillsCollapse .carbonFootprint{
    text-align: right;
    margin-right: 10px;
    margin-top: 22px;
}

div.aviationBottom {
    width: 100%;
    text-align: right;
    margin-top: 10px;
    padding-top:10px;
    margin-bottom: 10px;
    background-color: #161616;
}
  
div.aviationBottom .content {
    width: 100%;
    padding-right: 20px;
} 

@media screen and (max-width: 1199px) {
    .aviationPillsCollapse [class$="-freightCollapse"] {
        display: flex;
        width: 96.5%;
    }
    .aviationPillsCollapse [class$="-passengerCollapse"] {
        display: flex;
        width: 96.5%;
    }
    div.aviationBottom .content {
        width: 100%;
    } 
  }
  @media screen and (max-width: 992px) {
    .aviationPillsCollapse [class$="-freightCollapse"] {
        display: flex;
        width: 96.5%;
    }
    .aviationPillsCollapse [class$="-passengerCollapse"] {
        display: flex;
        width: 96.5%;
    }
    div.aviationBottom .content {
        width: 100%;
    } 
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .aviationPillsCollapse [class$="-freightCollapse"] {
        display: flex;
        width: 96.5%;
    }
    .aviationPillsCollapse [class$="-passengerCollapse"] {
        display: flex;
        width: 96.5%;
    }
    div.aviationBottom .content {
        width: 100%;
    } 
}

@media screen and (max-width: 500px) {
    div.aviationBottom .content {
        width: 100%;
    } 
}
