.customSelectTooltip {
	color: rgba(255, 255, 255, 0.87) !important;
	background-color: #000000 !important;
    padding: 6px !important;
    max-width: 300px;
    text-align: center !important;
	&.place-top {
        &:after {
            border-top-color: #000000 !important;
            border-top-style: solid !important;
            border-top-width: 6px !important;
        }
    }
}

.__react_component_tooltip .multi-line {
    text-align: center !important;
}