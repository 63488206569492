$saveIconColor: rgb(53, 155, 147);
$deleteIconColor: rgb(235, 79, 107);
$shareIconColor: #8b8be8;

.calculatorBottom {
    /* position: relative; */
    width: 100%;
    height: 70px;
    text-align: center;
    margin-top: 10px;
    padding-top:10px;
    background-color: #262626;
}
  
.calculatorBottom >.content {
    /* position: absolute; */
    /* right: 0; */
    /* width: 500px;
    height: 60px; */
    margin-left: 0;
    margin-right: 0;
} 

.calculatorActionSave {
    color: $saveIconColor;
    cursor: pointer;
    font-size: 16px; 
}

.calculatorActionSave:hover {
    color: lighten($saveIconColor, 20%);
}

.calculatorActionDelete {
    color: $deleteIconColor;
    cursor: pointer;
    font-size: 16px; 
}
  
.calculatorActionDelete:hover {
    color: lighten($deleteIconColor, 20%);
}

.calculatorActionShare {
    color: $shareIconColor;
    cursor: pointer;
    font-size: 16px; 
}
  
.calculatorActionShare:hover {
    color: lighten($shareIconColor, 10%);
}

.customCalculatorTooltip {
	color: rgba(255, 255, 255, 0.87) !important;
	background-color: #000000 !important;
  padding: 20px !important;
  text-align: left !important;
  max-width: 300px;
	&.place-top {
    &:after {
    border-top-color: #000000 !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
    }
	}
}

.plastic_disable { 
    pointer-events:none;
    opacity: 0.5 !important;
}