/* .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before {
    border-top-color:  !important;
} */

.react-datepicker{
    font-family: inherit !important;
    border: 2px solid #525252 !important;
    width: 100% !important;
}

.react-datepicker > button:focus {
    outline: none !important;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
    color: #f0f0f0;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
    background-color: #f0f0f0;
}

.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
    background-color: #525252;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range {
    border-radius: 0.3rem;
    background: #f0f0f0;
    color: black !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker__input-container {
    width: 100% !important;
}
.react-datepicker__month-container {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212 !important;
    width: 100% !important;
}

.react-datepicker-popper {
    top: -3px !important; 
    right: 26px !important;
}
.react-datepicker__triangle {
    left: 50% !important;
}

.react-datepicker__navigation--next {
    border-left-color: #989898  !important;
}
.react-datepicker__navigation--previous {
    border-right-color: #989898  !important;
}

.react-datepicker__navigation--next:hover {
    border-left-color: #262626 !important;
}

.react-datepicker__navigation--previous:hover {
    border-right-color: #262626 !important;
}

[id$="-react-datepicker-border"] {
    width: inherit !important;
    height: 38px !important;
    border-bottom-width: 1px;
    border-radius: 0px;
    border-color: transparent;
    border-bottom-color: white;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212 !important;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.87);
    padding-left: 10px;
}


[id$="-react-datepicker-no-border"] {
    width: inherit !important;
    height: 38px !important;
    border-bottom-width: 0px;
    border-radius: 0px;
    border-color: transparent;
    border-bottom-color: white;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212 !important;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.87);
    padding-left: 10px;
}

[id$="-errorDatePicker"] {
    width: inherit !important;
    height: 38px !important;
    border-bottom-width: 1px;
    border-radius: 0px;
    border-color: transparent;
    border-bottom-color: rgb(235, 79, 107);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.085), rgba(255, 255, 255, 0.085)), #121212 !important;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.87);
    padding-left: 10px;
}

[id$="-react-datepicker-border"]:hover {
    outline: unset !important;
}

[id$="-react-datepicker-no-border"]:hover {
    outline: unset !important;
}

[id$="-errorDatePicker"]:hover {
    outline: unset !important;
}

[id$="-react-datepicker-border"]:focus {
    outline: unset !important;
}

[id$="-react-datepicker-no-border"]:focus {
    outline: unset !important;
}

[id$="-errorDatePicker"]:focus {
    outline: unset !important;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
    width: 3rem;
    font-size: 10px;
}
