.fuelTabs .nav-tabs {
    border-bottom: 0px;
}

.fuelTabs .nav-item.show .nav-link, .fuelTabs .nav-tabs .nav-link.active{
    background-color: #262626;
    color: white;
    border-color: transparent;
}

.fuelTabs .nav-item.show .nav-link, .fuelTabs .nav-tabs .nav-link:focus{
    outline: none;
}

.fuelTabs .nav-item.show .nav-link, .fuelTabs .nav-tabs .nav-link{
    flex-grow: 1;
    text-align: left;
    margin-left: 3px;
    background-color: #444141;
    color: white;
    border: 0px;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    width: 0px;
}

.fuelTabs .nav-item.show .nav-link, .fuelTabs .nav-tabs .nav-link:first-child{
    margin-left: 0px;
}

.fuelTabs .nav-item{
    margin-bottom: 0px;
    font-size: 13px;
}

.fuelTabs .nav-item.show .nav-link, .fuelTabs .nav-tabs .nav-link.disabled {
    background-color: #444141;
    display: none;
}

.tab-content {
    background-color: #262626;
    color: white;
    /* height: 25vh; */
}

.tab-content .tab-content {
     height: 0vh; 
}

#fuelTabs .nav-link {
    padding-left: 10px;
}

.fuelPills .nav-link.active, .nav-pills .show>.nav-link {
    /* background-color: rgb(170 189 209) !important; */
    background-color: #F99500 !important;
    color: black !important;
    margin: 0;
    padding-bottom: 1px;
}

.fuelPills .nav-link, .nav-pills .show>.nav-link {
    margin: 0;
    font-size: 12px;
}

.fuelPills .nav-link {
    padding-left: 0.5rem;
    border-radius: 0.25rem;
    padding-top: 1px;
    padding-right: 0.5rem;
    padding-bottom: 1px;
    background-color: black;
    color: white;
    border-width: thin;
    border-style: solid;
    border-color: white;
}

.fuelPills .nav-item {
    margin: 0;
    margin-left: 10px;
    margin-top: 13px;
    padding-left: 10px;
}

.collapseButtons {
    margin: "10px";
    margin-left: "20px";
    text-align: "left";
    height: "100%";
    display: "flex";
    width: "30%";
}

.fuelPills{
    margin-bottom: 10px;
}

.fuelPills .nav-item1 {
    display: none;
}

.fuelPills .nav-link.disabled {
    color: #6c757d;
    background-color: #444141;
}

.fuelPills .form-control {
    height: calc(1.5em + .75rem - 10px);
}

.fuelPillsCollapse [class$="control"] {
    border-width: 0px;
    border-bottom-width: 3px !important;
    border-color: #F99500;
    box-shadow: 0 0 0 0px #6c757d !important;
    background: white !important;
    height: 40px;
}


.fuelPillsCollapse [class$="control"]:hover {
    border-color: #F99500;
}

.fuelPillsCollapse [class$="single-value"]:active  {
    background-color: #6c757d;
}



.fuelPillsCollapse [class$="-fuelConsumptionCollapse"] {
    display: flex;
    margin: 0px;
    margin-top: 10px;
    width: 96.5%;
}

.fuelPillsCollapse [class$="-fuelCostCollapse"] {
    display: flex;
    margin: 0px;
    margin-top: 10px;
    width: 96.5%;
}


.fuelPillsCollapse [class$="-distanceCollapse"] {
    display: flex;
    margin: 0px;
    margin-top: 10px;
    width: 96.5%;
}

.fuelPillsCollapse [class$="-vtCollapse"] {
    display: flex;
    margin: 0px;
    margin-top: 10px;
    width: 100%;
}


.fuelPillsCollapse .inactivebtn {
    font-size: 12px;
    padding-left: 0.5rem;
    border-radius: 0.25rem;
    padding-top: 1px;
    padding-right: 0.5rem;
    padding-bottom: 1px;
    background-color: black;
    color: white;
    border-width: thin;
    border-style: solid;
    border-color: white;
    margin: 0;
    margin-right: 5px;
    margin-bottom: 4px;
  }
  
  .fuelPillsCollapse .inactivebtn:hover {
    color: white !important;
  }
  
  .fuelPillsCollapse .activebtn {
    font-size: 12px;
    padding-left: 0.5rem;
    border-radius: 0.25rem;
    padding-top: 1px;
    padding-right: 0.5rem;
    padding-bottom: 1px;
    border-width: thin;
    border-style: solid;
    border-color: white;
    background-color: #F99500 !important;
    color: black;
    margin: 0;
    margin-right: 5px;
    margin-bottom: 4px;
  }
  
.fuelPillsCollapse .activebtn:hover {
    color: black !important;
}

.fuelPillsCollapse .vehicleType {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.fuelPillsCollapse .carbonFootprint{
    text-align: right;
    margin-right: 10px;
}

div.fuelBottom {
    width: 100%;
    text-align: right;
    margin-top: 10px;
    padding-top:10px;
    margin-bottom: 10px;
    background-color: #161616;
}
  
div.fuelBottom .content {
    width: 100%;
    padding-right: 20px;
} 

@media screen and (max-width: 1199px) {
    .fuelPillsCollapse [class$="-fuelConsumptionCollapse"] {
        display: flex;
        width: 96.5%;
    }
    .fuelPillsCollapse [class$="-distanceCollapse"] {
        display: flex;
        width: 96.5%;
    }
    .fuelPillsCollapse .vehicleType {
        width: 96.5%;
        margin-left: 0;
        margin-right: 0;
    }
    div.fuelBottom .content {
        width: 100%;
    } 
  }
  @media screen and (max-width: 992px) {
    .fuelPillsCollapse [class$="-fuelConsumptionCollapse"] {
        display: flex;
        width: 96.5%;
    }
    .fuelPillsCollapse [class$="-distanceCollapse"] {
        display: flex;
        width: 96.5%;
    }
    .fuelPillsCollapse .vehicleType {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    div.fuelBottom .content {
        width: 100%;
    } 
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .fuelPillsCollapse [class$="-fuelConsumptionCollapse"] {
        display: flex;
        width: 96.5%;
    }
    .fuelPillsCollapse [class$="-distanceCollapse"] {
        display: flex;
        width: 96.5%;
    }
    .fuelPillsCollapse .vehicleType {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    div.fuelBottom .content {
        width: 100%;
    } 
}

@media screen and (max-width: 500px) {
    div.fuelBottom .content {
        width: 100%;
    } 
}

