$fuelColor: #F99500;
$electricityColor: #FFFF00;
$deliveryColor: #7444DA;
$paperColor: #FFFFF0;
$plasticColor: #40A0B5;
$metalColor: #d7dbdc;
$transportColor: #f37d7d;
$electricalItemsColor: #228C22;
$aviationColor: #6f91ad;
$seaFreightColor: #009dc4;
$wasteColor: #40A0B5;
$dataCentreColor: #66FFCC;
$waterColor: #148AFF;
$newDeliveryColor: #7444DA;


.card {
    border-radius: 0 !important;
    margin: 2px !important;
}

.homeCard {
    border-radius: 0 !important;
    margin: 10px !important;
}

.dataCard:hover {
    background-color: #52525285 !important;
}

.calculatorCard:hover {
    background-color: #52525285 !important;
    
    .imgFuel {
        fill: $fuelColor;
    }
    
    .imgElectricity {
        fill: $electricityColor;
    }

    .imgSimpleDeliveryImpact {
        fill: $deliveryColor;
    }

    .imgPaper {
        fill: $paperColor;
    }

    .imgPlastic {
        fill: $plasticColor;
    }

    .imgMetal {
        fill: $metalColor;
    }

    .imgTransport {
        fill: $transportColor;
    }

    .imgElectricalItems {
        fill: $electricalItemsColor;
    }

    .imgAviation {
        fill: $aviationColor;
    }

    .imgSeaFreight {
        fill: $seaFreightColor;
    }
    
    .imgDataCentre {
        fill: $dataCentreColor;
    }

    .imgWater {
        fill: $waterColor;
    }

    .imgDetailedProjectImpact {
        fill: $newDeliveryColor;
    }
}

.noCalculatorCard {
    cursor: not-allowed;

    .imgPaper {
        fill: white;
        fill-opacity: 0.3;
    }
}

.newCard {
    background-color: rgba(255, 255, 255, 0.87);
}

.newCard:hover {
    background-color: rgba(255, 255, 255, 0.70) !important;
}

.card-header {
    padding: 0;
    margin-bottom: 12px;
}

.cardIconFuel {
    color: $fuelColor;
}

.cardIconElectricity {
    color: $electricityColor;
}

.cardIconSimpleDeliveryImpact {
    color: $deliveryColor;
}

.cardIconPaper {
    color: $paperColor;
}

.cardIconPlastic {
    color: $plasticColor;
}

.cardIconMetal {
    color: $metalColor;
}

.cardIconTransport {
    color: $transportColor;
}

.cardIconElectricalItems {
    color: $electricalItemsColor;
}

.cardIconAviation {
    color: $aviationColor;
}

.cardIconSeaFreight {
    color: $seaFreightColor;
}

.cardIconWaste {
    color: $wasteColor;
}

.cardIconDataCentre {
    color: $dataCentreColor;
}

.cardIconWater {
    color: $waterColor;
}

.cardIconDetailedProjectImpact {
    color: $newDeliveryColor;
}

.customCardTooltip {
	color: rgba(255, 255, 255, 0.87) !important;
	background-color: #000000 !important;
  padding: 6px !important;
  text-align: center !important;
  max-width: 300px;
	&.place-top {
    &:after {
    border-top-color: #000000 !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
    }
	}
}

.__react_component_tooltip .multi-line {
  text-align: center !important;
}

.customTooltip {
	color: rgba(255, 255, 255, 0.87) !important;
	background-color: rgb(38, 38, 38) !important;
	padding: 10px !important;
	&.place-top {
	&:after {
	border-top-color: rgb(38, 38, 38) !important;
	border-top-style: solid !important;
	border-top-width: 6px !important;
	}
	}
}

.st0{
    fill:#000000;
}