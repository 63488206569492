$backIconColor: blue;
$deleteIconColor: rgb(235, 79, 107);

.addUsersActionBack {
    color: $backIconColor;
    cursor: pointer;
}
  
.addUsersActionBack:hover {
    color: lighten($backIconColor, 20%);
}

.addUsersActionDelete {
    color: $deleteIconColor;
    cursor: pointer;
}