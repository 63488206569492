/* Declare some variables */
$base-color: #262626;
$light-background: #e7e6e8;

.current-page {
  font-size: 1.5rem;
  vertical-align: middle;
}

// Override some Bootstrap pagination styles
ul.pagination {
  margin-top: 0;
  margin-bottom: 0;
  justify-content: center;
  // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  li.page-item.active {
    a.page-link {
      color: saturate(darken($base-color, 50%), 5%) !important;
      background-color: $light-background !important;
      border-color: $base-color !important;
    }
  }

  a.page-link {
    // padding: 0.75rem 1rem;
    // min-width: 3.5rem;
    padding: 0.5rem 1rem;
    min-width: 2.5rem;
    text-align: center;
    box-shadow: none !important;
    border-color: $base-color !important;
    color: saturate(lighten($base-color, 60%), 10%);
    font-weight: 900;
    font-size: 1rem;
    background-color: $base-color;

    &:hover {
      background-color: #525252;
      color: white;
    }
  }
}