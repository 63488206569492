
.footer-div {
    background-color: rgba(255, 255, 255, 0.07) !important;
    height: auto;
    min-height: 97px;
    width: 100%;
    /* position: absolute; */
    bottom: 0;
    z-index: 100;
    text-align: center;
    color: white;
    display: flex;
    /* padding: 37px 5px 0px 5px; */
}