.accordion__button {
    background-color: #161616 !important;
    border: 0 !important;
    color: #f0f0f0 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-size: 16px !important;
    letter-spacing: 0.7px;
}

.accordion__button:hover {
    background-color: #e7e6e81a !important;
}

.accordion__button:focus {
    outline: none !important;
}

.accordion__button:before {
    margin-top: 5px;
}

.accordion__button[aria-expanded='true']::before, .accordion__button[aria-selected='true']::before {
    margin-bottom: 2px;
    // position: absolute;
    // right: 25px;
}

.accordion__button[aria-expanded='false']::before, .accordion__button[aria-selected='false']::before {
    margin-bottom: 2px;
    // position: absolute;
    // right: 25px;
}

.accordion__panel {
    font-size: 13px;
    padding-left: 42px !important;
    letter-spacing: 0.2px;
    padding-top: 3px !important;
    padding-bottom: 10px !important;
}
