.toggleTextGS {
    margin-left: 17px;
    margin-top: 9px;
    margin-right: 20px;
    font-size: 12px;
}

.toggleTextInclude {
    margin-left: 5px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.toggleTextNotInclude {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.businessTraveltoggleTextNotInclude {
    margin-left: 80px;
    margin-top: 10px;
    margin-bottom: 10px;
}