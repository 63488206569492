.navbar-brand{
    margin-left: 1rem !important;
  }
  
.navbar{
    background-color: #161616 !important;
    /* height: 40px; */
    /* padding-left: 0px;
    padding-right: 0px; */
    padding: 0px;
}
  

.nav-pills .nav-link, .nav-pills .show>.nav-link {
    background-color: inherit!important;
    color: white !important;
    padding-left: 10px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #525252 !important;
    color: white !important;
}


  
#loginDropdown>.btn {
    color: white !important;
    box-shadow: none !important;
    padding-top: 7px;
    padding-left: 10px;
}

#loginDropdown>.btn.focus {
    color: white !important;
    box-shadow: none !important;
    padding-top: 7px;
    padding-left: 10px;
}
  
#loginDropdown>.btn:focus {
    color: white !important;
    box-shadow: none !important;
    padding-top: 7px;
}

.navbar-toggler {
    background-color: #e7e6e8 !important;
}

.navbar-light .navbar-toggler {
    margin: 10px;
}

.logoName {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: rgba(255, 255, 255, 0.87);
    margin-bottom: -13px;
}

.logoName2 {
    font-family: Ubuntu Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: rgba(255, 255, 255, 0.87);
    margin-bottom: 0px;
}

.dropdown-item.active, .dropdown-item:active {
    color: inherit !important;
    background-color: inherit !important;
}

  