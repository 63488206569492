$fuelColor: #F99500;
$electricityColor: #FFFF00;
$deliveryColor: #7444DA;
$paperColor: #FFFFF0;
$plasticColor: #40A0B5;
$metalColor: #d7dbdc;
$wasteColor: #40A0B5;
$dataCentreColor: #66FFCC;
$waterColor: #148AFF;
$transportColor: #f37d7d;
$electricalItemsColor: #228C22;
$aviationColor: #6f91ad;
$seaFreightColor: #009dc4;

.summary-header {
    width: 100%;
    height: 2.2rem;
    display: inline-block;
}

.badge-light {
    font-size: '1.2rem';
}

.summary {
    padding: 5px;
    background: #D1E5E1;
}

.summary:focus {
    box-shadow: 0 0 black !important;
}

.summary:active {
    box-shadow: 0 0 black !important;
}

.summary:active:focus {
    box-shadow: 0 0 black !important;
}

.summary-entry-header {
    font-size: 12px;
    width: inherit;
    padding: 3px;
    padding-right: 0px;
    text-align: right;
    margin: 0px;
    margin-top: 10px;
    font-family: "Ubuntu Light";
}

.summary-entry {
    font-size: 11px;
    background-color: #c3c3c345;
    width: inherit;
    margin: inherit;
    padding: 3px;
    margin-top: 3px;
    padding-right: 0px;
    text-align: right;
}

.summary-entry .itemType {
    padding: 3px 5px;
    border-radius: 5px;
    background-color: #cacacab0;
    color: black;
}

.summary-entry .itemType:hover {
    opacity: 0.8;
}

.scrollLink {
    color: inherit !important;
    cursor: pointer;
    padding-top: 3px;
    padding-bottom: 3px;

    &.fuel {
        background: linear-gradient(to right, transparent, $fuelColor 200%) left;
        background-size: 200%;
        transition: .5s ease-out;
    }

    &.electricity {
        background: linear-gradient(to right, transparent, $electricityColor 200%) left;
        background-size: 200%;
        transition: .5s ease-out;
    }

    &.paper {
        background: linear-gradient(to right, transparent, $paperColor 200%) left;
        background-size: 200%;
        transition: .5s ease-out;
    }

    &.dataCentre {
        background: linear-gradient(to right, transparent, $dataCentreColor 200%) left;
        background-size: 200%;
        transition: .5s ease-out;
    }

    &.water {
        background: linear-gradient(to right, transparent, $waterColor 200%) left;
        background-size: 200%;
        transition: .5s ease-out;
    }

    &.plastic {
        background: linear-gradient(to right, transparent, $plasticColor 200%) left;
        background-size: 200%;
        transition: .5s ease-out;
    }

    &.metal {
        background: linear-gradient(to right, transparent, $metalColor 200%) left;
        background-size: 200%;
        transition: .5s ease-out;
    }

    &.transport {
        background: linear-gradient(to right, transparent, $transportColor 200%) left;
        background-size: 200%;
        transition: .5s ease-out;
    }

    &.electricalItems {
        background: linear-gradient(to right, transparent, $electricalItemsColor 200%) left;
        background-size: 200%;
        transition: .5s ease-out;
    }

    &.aviation {
        background: linear-gradient(to right, transparent, $aviationColor 200%) left;
        background-size: 200%;
        transition: .5s ease-out;
    }

    &.seaFreight {
        background: linear-gradient(to right, transparent, $seaFreightColor 200%) left;
        background-size: 200%;
        transition: .5s ease-out;
    }

    &.delivery {
        background: linear-gradient(to right, transparent, $deliveryColor 200%) left;
        background-size: 200%;
        transition: .5s ease-out;
    }
}

.scrollLink:hover {
    color: inherit !important;
    cursor: pointer;
    background-position: right;
}

.customCalculatorTooltip {
    color: rgba(255, 255, 255, 0.87) !important;
    background-color: #000000 !important;
    padding: 20px !important;
    text-align: center !important;
    max-width: 330px;
    font-weight: 300;

    &.place-top {
        &:after {
            border-top-color: #000000 !important;
            border-top-style: solid !important;
            border-top-width: 6px !important;
        }
    }

}

.url-download {
    color:#007bff;
}

.url-download:hover {
    cursor: pointer;
}